import { UniversalHeaderConfig } from '@amzn/sitc-frontend/hooks';
import { SearchResult } from '@amzn/sitc-frontend/types';

const getSearchResultNavigatePath = (searchResult: SearchResult): string => {
  const { vertexId } = searchResult;
  return `/universal-header-origin-search/${vertexId}`;
};

export const universalHeaderConfig: UniversalHeaderConfig = {
  productIdentifier: 'Demo Micro-App',
  headerLinks: [
    {
      primary: 'Common User Preferences',
      path: 'common-user-preferences',
      reloadOnNav: false,
    },
    {
      primary: 'Micro-App User Preferences',
      path: 'micro-app-user-preferences',
      reloadOnNav: false,
    },
    {
      primary: 'Revision History',
      path: 'revision-history',
      reloadOnNav: false,
    },
    {
      primary: 'Origin Search',
      path: 'origin-search',
      reloadOnNav: false,
    },
  ],
  originSearch: {
    enable: true,
    getSearchResultNavigatePath,
  },
};
