import { ErrorBoundary, StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { MicroAppRegistryInfo } from '@amzn/sitc-frontend-micro-app-sdk-core';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import StudiosDemoMicroApp from './components/StudiosDemoMicroApp';

const configuration = {
  appId: 'standalone-micro-app',
};

const radioConfig = { channelNames: ['revision-history', 'origin-search'] };

const microApps: MicroAppRegistryInfo[] = [
  {
    microAppName: 'amzn-studios-revision-history-app',
    packageName: '@amzn/studios-revision-history-app',
    version: '*',
    accessLevel: 'restricted',
  },
  {
    microAppName: 'amzn-studios-origin-search-app',
    packageName: '@amzn/studios-origin-search-app',
    version: '*',
    accessLevel: 'restricted',
  },
];

const rootComponent = process.env.STANDALONE
  ? () => (
      <StandaloneConfiguration
        RootComponent={StudiosDemoMicroApp}
        configuration={configuration}
        microAppName="amzn-studios-demo-micro-app"
        microApps={microApps}
        radioConfig={radioConfig}
      />
    )
  : StudiosDemoMicroApp;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error, info: React.ErrorInfo) => <ErrorBoundary error={error} info={info} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
